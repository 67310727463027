const Logo = ({ scale, color }) => {
    return (
<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${232*scale} ${232*scale}`} width={`${232*scale}`} height={`${232*scale}`}>
  <g transform={`scale(${scale})`}>
    <path style={{ fill: color}} d="M.03,149.77c0-6.9,0-12.62,0-18.33,0-.42-.18-1,.34-1.15.49-.15.69.41.94.75,1.64,2.25,4,3.41,6.5,4.43,4.08,1.67,8.11,3.47,12.17,5.18,4.06,1.72,8.15,3.4,12.21,5.12,3.99,1.7,7.96,3.43,11.95,5.14,3.62,1.56,7.28,3,10.91,4.51,3.75,1.56,7.63,2.38,11.62,2.81.68.07.69.39.48.82-1.56,3.08-1.91,6.4-1.92,9.78-.02,10.19-.12,20.38.06,30.56.06,3.35.19,6.8,1.44,9.99.93,2.38,1.91,4.82,4.13,6.41.44.31.78.73.61,1.31-.21.68-.81.3-1.22.32-1.37.07-2.57-.56-3.78-1.07-6.31-2.69-12.59-5.42-18.91-8.1-3.64-1.54-7.31-3.01-10.97-4.5-4.7-1.91-9.4-3.78-13.98-5.99-3.18-1.53-6.25-3.21-9-5.35-2.51-1.95-4.82-4.17-6.87-6.64-1.77-2.13-3.08-4.5-4.13-7.01-.93-2.22-1.54-4.54-1.89-6.95-1.14-7.73-.46-15.5-.68-22.06Z"/>
    <path style={{ fill: color}} d="M164.14,162.29c-3.5.29-5.4-1.66-6.97-4.15-1.27-2.01-1.62-4.36-2.43-6.55-1.22-3.31-3.1-6.02-5.85-8.33-3.6-3.01-7.92-4.44-12.05-6.3-4.29-1.94-8.63-3.76-12.97-5.58-5.05-2.12-10.13-4.14-15.17-6.27-2.43-1.03-4.8-2.21-7.19-3.33-.09-.04-.52.1-.19-.27.18-.21.04-.55.5-.68,5.59-1.64,11.02-3.77,16.54-5.62,7.5-2.51,14.94-5.2,22.38-7.86,1.9-.68,3.86-1.15,5.78-1.77,1.32-.43,2.76.02,4.05.48,4.7,1.68,9.41,3.33,14.05,5.15,5.98,2.36,12.07,4.45,18,6.93,4.32,1.8,8.72,3.4,13.08,5.11,3.76,1.47,7.44,3.06,9.77,6.65,1.52,2.33,2.36,4.84,2.79,7.63.86,5.58-4.95,12.18-9.85,13.6-4.69,1.36-9.34,2.98-13.89,4.82-3.32,1.34-6.78,2.31-10.16,3.49-2.75.96-5.51,1.91-8.31,2.72-.72.21-1.42.05-1.9.14Z"/>
    <path style={{ fill: color}} d="M97.87,167.81c-.06-2.11.17-5.51-.11-8.9-.4-4.75-1.97-9.08-4.9-12.83-2.45-3.13-5.67-5.31-9.29-6.92-3.81-1.7-7.51-3.65-11.4-5.16-.84-.32-1.69-.61-2.5-.99-.35-.16-.91-.24-.82-.85.08-.57.59-.26.87-.36,5.13-1.79,10.28-3.54,15.42-5.29,1.28-.44,2.59-.82,3.87-1.28.87-.31,1.66.1,2.44.25,2.7.5,5.25,1.5,7.77,2.56,4.65,1.95,9.3,3.88,13.96,5.81,7.1,2.94,14.2,5.9,21.31,8.82,2.55,1.05,5.19,1.93,7.57,3.33,3.84,2.25,6.61,5.29,7.41,9.91.88,5.08-.72,9.25-4.21,12.82-1.6,1.64-3.69,2.56-5.78,3.32-6.08,2.21-12.2,4.32-18.27,6.56-4.82,1.79-9.75,3.28-14.59,5.01-2.4.85-4.34.11-6.27-1.21-1.79-1.23-2.55-2.88-2.49-5.04.08-2.75.02-5.51.02-9.55Z"/>
    <path style={{ fill: color}} d="M133.62,68.91c0-10.39-.03-20.78.02-31.17,0-2.22-1.15-3.68-2.73-4.94-3.55-2.83-7.4-2.52-11.4-1.11-8.18,2.89-16.38,5.71-24.57,8.58-7.26,2.54-14.51,5.12-21.78,7.63-9.61,3.31-19.26,6.53-28.85,9.88-4.41,1.54-7.96,4.32-10.96,7.92-1.9,2.28-3.18,4.77-3.72,7.64-.24,1.28-.38,2.63-.24,3.92.27,2.47-.11,4.92-.04,7.39.08,2.73-.06,5.5-.08,8.24-.02,3.42-.09,6.84.06,10.24.14,3.26-.22,6.49-.08,9.74.03.65.02.9-.84.62-2.68-.87-5.27-1.98-7.91-2.95-.99-.36-2-.66-3-.99-.67-.22-.96-.6-.96-1.38.03-11.19-.02-22.38.05-33.57.02-3.34.18-6.7,1.6-9.83,1.6-3.51,3.91-6.47,6.8-9,1.98-1.74,4.14-3.15,6.57-4.23,2.75-1.23,5.65-2.03,8.47-3.06,5.45-1.98,10.94-3.83,16.44-5.67,4.17-1.39,8.33-2.8,12.44-4.37.37-.14.74-.22,1.14-.09,3.65,1.22,7.06.39,10.32-1.34,2.17-1.14,4.13-2.6,6.12-4.01,1.31-.93,3.09-1.19,4.67-1.73,4.2-1.43,8.41-2.86,12.62-4.25,4.57-1.52,9.14-3.01,13.73-4.48,3.07-.99,6.17-.85,9.31-.27,4.35.81,7.96,2.92,11.12,5.93,1.97,1.87,2.84,4.2,2.84,6.86.01,16.51-.02,33.01.04,49.52,0,2.19.05,4.4.1,6.59.05,2.16.2,4.34.1,6.51-.04.76-.21,1.48-1.06,1.76-1.87.61-3.57,1.61-5.44,2.24-.8.27-.91,0-.92-.63-.01-1.4,0-2.8,0-4.2,0-9.31,0-18.62,0-27.94h0Z"/>
    <path style={{ fill: color}} d="M146.12,176.02c4.73-1.66,9.46-3.32,14.2-4.98,9.09-3.19,18.19-6.37,27.28-9.56,6.28-2.2,12.55-4.4,18.82-6.63,1.78-.63,1.77-.68,1.77,1.21,0,4.55.02,9.11,0,13.66,0,1.15.32,2.28.09,3.45-.12.6-.32.91-.92,1.12-3.6,1.25-7.19,2.55-10.79,3.83-6.2,2.2-12.39,4.42-18.6,6.58-6.63,2.31-13.3,4.54-19.93,6.86-4.01,1.41-7.94,3.04-11.97,4.37-5.42,1.8-10.76,3.81-16.15,5.69-3.96,1.38-7.92,2.78-11.88,4.16-6.43,2.23-12.86,4.42-19.28,6.69-.96.34-1.12.01-1.12-.81,0-5.86,0-11.72,0-17.58,0-.76.36-1.26,1.06-1.5,4.2-1.46,8.4-2.9,12.6-4.36,5.95-2.08,11.9-4.17,17.85-6.25,3.43-1.19,6.87-2.36,10.3-3.53-.05.17-.1.34-.15.52.39.08.71-.05.98-.32l.05-.03c.25.01.51.07.67-.21l.04-.02c.18,0,.35-.02.44-.21l.05-.03c.25,0,.51.07.67-.21l.05-.03c.25,0,.52.07.68-.22l.03-.02c.66-.17,1.33-.3,1.9-.7l.02-.03c.43-.02.48-.33.5-.66.11,0,.21,0,.32,0,.33.62.67.29,1.11.02-.29-.11-.5-.19-.7-.26Z"/>
    <path style={{ fill: color}} d="M91.87,184.08c0,8.55,0,17.1,0,25.65,0,1.72-1.44,3.86-3.22,4.34-4.63,1.25-9.13,1.11-12.81-2.39-2.36-2.24-3.82-4.89-3.92-8.44-.39-13.13-.04-26.26-.16-39.39-.02-2.62.92-4.97,2.8-6.88,2.23-2.27,4.76-4,7.92-4.8,4.43-1.11,8.08.94,9.29,5.32.15.56.08,1.11.08,1.66,0,8.31,0,16.62,0,24.93h.02Z"/>
    <path style={{ fill: color}} d="M213.74,137.08c0-8.63.03-17.26-.02-25.89-.01-2.38.75-4.45,2.04-6.33.79-1.16,2.08-1.88,3.48-1.96,2.83-.15,5.59.02,8.05,1.84,2.36,1.75,3.5,4.18,4.37,6.8.53,1.6.78,3.26.77,4.98-.03,13.02.08,26.05-.07,39.07-.04,4.04-1.94,7.44-5.09,10.09-2.02,1.7-4.18,3.14-6.6,4.17-.92.39-1.89.43-2.9.27-2.57-.4-3-2.58-3.76-4.47-.32-.8-.29-1.7-.29-2.57.01-8.67,0-17.34,0-26.01h0Z"/>
    <path style={{ fill: color}} d="M12.04,116.8c2.84-.34,5.44.81,7.99,1.95,5.21,2.32,10.47,4.52,15.75,6.67,3.69,1.51,7.28,3.27,10.98,4.75,5.88,2.36,11.67,4.94,17.58,7.25,4.33,1.69,8.57,3.65,12.85,5.47,1.91.81,3.83,1.59,5.74,2.42.27.12.82.05.75.55-.05.39-.47.31-.78.38-3.09.72-6.04,1.76-8.7,3.56-.47.32-.94.66-1.22,1.08-1.16,1.76-2.88,1.81-4.64,1.58-2.07-.28-4.15-.57-6.15-1.24-2.85-.96-5.61-2.16-8.4-3.28-4.83-1.93-9.73-3.73-14.49-5.82-5.28-2.31-10.68-4.32-15.96-6.64-3.83-1.69-7.74-3.19-11.59-4.83-2.73-1.16-5.42-2.41-7.44-4.7-1.84-2.08-2.18-5.44.08-7.2,1.25-.98,2.73-1.43,4.26-1.76,1.09-.24,2.2-.24,3.4-.19Z"/>
    <path style={{ fill: color}} d="M222.56,96.31c-3.96,0-7.17,1.2-10.1,3.21-1.76,1.21-3.11,2.8-4.06,4.68-.56,1.11-1.29,1.2-2.29.87-3.86-1.26-7.59-2.86-11.36-4.34-5.34-2.09-10.67-4.2-15.99-6.33-8.84-3.56-17.67-7.14-26.5-10.72-1.43-.58-2.83-1.26-4.25-1.87-.79-.34-1.13-.83-1.02-1.75.13-1.1.21-2.26.02-3.34-.3-1.75.82-2.47,2.01-3.07,4.06-2.08,8.31-1.99,12.7-1.18,5.45,1,10.35,3.48,15.43,5.46,8.2,3.21,16.35,6.55,24.52,9.82,6.69,2.67,13.38,5.3,20.07,7.96.2.08.36.26.82.61Z"/>
    <path style={{ fill: color}} d="M207.22,117.2c0,1-.03,2,0,3,.04.86-.22.96-1.04.7-2.33-.72-4.49-1.84-6.74-2.73-6.79-2.69-13.57-5.43-20.35-8.15-7.98-3.2-15.97-6.39-23.95-9.6-2.5-1-4.98-2.05-7.49-3.03-.66-.26-.88-.61-.85-1.31.09-2.07.18-4.14.01-6.21-.06-.76.14-.92.92-.61,11.37,4.57,22.77,9.08,34.14,13.65,6.27,2.52,12.53,5.09,18.85,7.51,1.9.73,3.77,1.52,5.63,2.34.51.22.98.53.89,1.32-.12,1.03-.03,2.07-.03,3.11Z"/>
    <path style={{ fill: color}} d="M76.02,33.76c-.89.11-1.52-.19-2.2-.83-4.47-4.19-7.44-9.47-10.72-14.53-.73-1.13-1.61-2.18-1.99-3.51-.19-.66-.33-1.24.08-1.9.48-.76.99-.93,1.81-.51,2.22,1.16,3.54,3.22,5.02,5.09,2.43,3.07,4.72,6.26,7.05,9.4.33.44.6.92.9,1.37.72,1.1,1,1.12,1.73.06,1.21-1.76,2.41-3.54,3.62-5.31,2.41-3.55,4.78-7.13,7.23-10.65.83-1.19,1.82-2.27,2.78-3.37.96-1.09,2.1-1.43,3.03-.74,1.05.78,1.23,1.87.42,3.21-1.07,1.77-2.18,3.52-3.34,5.24-1.91,2.83-3.5,5.87-5.55,8.61-2.16,2.88-4.52,5.58-7.42,7.74-.71.53-1.53.79-2.43.62Z"/>
    <path style={{ fill: color}} d="M77.01,226.4c0-1.2.04-2.4-.01-3.59-.03-.7.23-.9.89-.88,1.91.07,3.82.12,5.72-.13,2.92-.39,5.72-1.33,8.56-2.07,1.61-.41,3.13-1.17,4.88-1.05.85.06,1.27.35,1.27,1.2-.01,1.76-.05,3.51-.06,5.27,0,.68-.3,1.14-.91,1.4-4.69,2.04-9.38,4.09-14.09,6.09-.89.38-1.69-.28-2.5-.54-1.25-.39-2.98-.48-3.55-1.48-.59-1.03-.18-2.63-.21-3.98,0-.08,0-.16,0-.24h0Z"/>
    <path style={{ fill: color}} d="M215.15,180.17c.63,2.25-.51,3.24-2.51,3.96-2.83,1.02-5.56,2.3-8.32,3.51-.97.43-1.89.29-2.82-.04-2.92-1.05-2.9-1.06-2.89-4.25,0-.7.22-1.08.85-1.37,3.13-1.45,6.38-2.57,9.67-3.6,1.57-.49,3.08-1.12,4.76-1.21,1.05-.05,1.36.34,1.26,1.31-.06.55-.01,1.12-.01,1.68Z"/>
    <path style={{ fill: color}} d="M23.24,204.34c-2.79,1.2-5.74,1.7-8.53,2.74-1.07.4-2.11-.34-3.13-.65-1.98-.6-1.98-.62-1.98-2.57,0-2.44,0-4.87.02-7.31,0-.28-.21-.68.2-.84.3-.12.53.14.73.32,3.26,2.89,7.16,4.77,10.9,6.9.66.38,1.44.63,1.8,1.42Z"/>
    <path style={{ fill: color}} d="M61.56,7.62c.27,2.72-1.51,3.67-4.35,4.01-1.88.23-3.59-1.79-3.68-4.18-.07-1.83,1.65-3.53,3.69-3.65,2.68-.16,4.2,1.18,4.34,3.82Z"/>
    <path style={{ fill: color}} d="M97.1,7.15c-1.81-.13-3.94-1.12-4.04-3.16-.09-1.95,1.67-3.96,3.68-3.98,2.23-.02,3.8,1.29,4.01,3.11.21,1.8-1.6,4.27-3.65,4.04Z"/>
    <path style={{ fill: color}} d="M139.46,178.47c1.09-.63,2.28-.96,3.5-1.24,0,.14,0,.28.01.43,0,0-.03.02-.03.02-.25,0-.52-.07-.68.22,0,0-.05.03-.05.03-.25,0-.51-.07-.67.21,0,0-.05.03-.05.03-.19-.01-.35.02-.44.21,0,0-.04.02-.04.02-.25,0-.52-.07-.67.21,0,0-.05.03-.05.03-.28-.06-.55-.11-.83-.17Z"/>
    <path style={{ fill: color}} d="M142.97,177.65c0-.14,0-.28-.01-.43.07-.03.14-.05.21-.05.09-.06.18-.13.26-.19.15-.02.3-.03.45-.05.33,0,.66.01.99.02-.57.4-1.24.53-1.9.7Z"/>
    <path style={{ fill: color}} d="M146.12,176.02c.21.08.41.15.7.26-.44.27-.78.6-1.11-.02,0,0,0,0,0,0,.22.07.33-.05.39-.24h.02Z"/>
    <path style={{ fill: color}} d="M144.87,176.95c-.33,0-.66-.01-.99-.02.28-.27.58-.48,1-.43,0,.14.01.28.02.42l-.02.03Z"/>
    <path style={{ fill: color}} d="M139.46,178.47c.28.06.56.11.83.17-.28.27-.6.4-.98.32.05-.17.1-.34.15-.52,0,0,0,.03,0,.03Z"/>
    <path style={{ fill: color}} d="M144.89,176.93c0-.14-.01-.28-.02-.42.07-.03.14-.05.21-.05.1-.06.2-.13.3-.19,0,0,0,0,0,0-.02.33-.07.64-.5.66Z"/>
    <path style={{ fill: color}} d="M146.1,176.02c-.06.19-.16.31-.39.24.12-.09.14-.34.39-.24Z"/>
    <path style={{ fill: color}} d="M145.39,176.26c-.1.06-.2.13-.3.19.04-.16.11-.27.3-.19Z"/>
    <path style={{ fill: color}} d="M143.44,176.98c-.09.06-.18.13-.26.19.04-.13.09-.24.26-.19Z"/>
    <path style={{ fill: color}} d="M142.26,177.89c.16-.28.43-.22.68-.22-.16.28-.43.22-.68.22Z"/>
    <path style={{ fill: color}} d="M141.54,178.13c.16-.28.42-.22.67-.21-.16.28-.42.22-.67.21Z"/>
    <path style={{ fill: color}} d="M140.35,178.61c.16-.28.43-.21.67-.21-.16.28-.42.22-.67.21Z"/>
    <path style={{ fill: color}} d="M141.06,178.37c.09-.19.25-.22.44-.21-.09.19-.25.22-.44.21Z"/>
  </g>
</svg>
    );
};

export default Logo;